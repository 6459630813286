require('turbolinks').start();

import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) { Rails.start(); }

import '@popperjs/core'

require.context('../fonts', true)
require.context('../images', true)

import '@fortawesome/fontawesome-free/css/all.css'

import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/modal'
window.Modal = require('bootstrap/js/dist/modal')

import '../stylesheets/application.sass'
